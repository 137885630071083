import React, { useEffect } from "react";
import {
  HeroSection,
  Features,
  SomeProducts,
  BanerBg,
} from "../components/index";
function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <HeroSection />
      <Features />
      <SomeProducts />
      <BanerBg />
    </div>
  );
}

export default Home;
