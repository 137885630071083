import {
  LegumesSecs1,
  LegumesSecs2,
  LegumesSecs3,
  LegumesSecs4,
  LegumesSecs5,
  LegumesSecs6,
  LegumesSecs7,
  LegumesSecs8,
  LegumesSecs9,
  LegumesSecs10,
  LegumesSecs11,
  LegumesSecs12,
  LegumesSecs13,
  LegumesSecs14,
  LegumesSecs15,
  LegumesSecs16,
  LegumesSecs17,
  LegumesSecs18,
  LegumesSecs19,
  LegumesSecs20,
  LegumesSecs21,
  LegumesSecs22,
  LegumesSecs23,
  LegumesSecs24,
  LegumesSecs25,
  LegumesSecs26,
  LegumesSecs27,
  LegumesSecs28,
  LegumesSecs29,
  LegumesSecs30,
  LegumesSecs31,
  LegumesSecs32,
  LegumesSecs33,
  LegumesSecs34,
  LegumesSecs35,
  LegumesSecs36,
  LegumesSecs37,
  LegumesSecs38,
} from "./assets/Légumes secs/index";

import {
  FarineSemoule1,
  FarineSemoule2,
  FarineSemoule3,
  FarineSemoule4,
  FarineSemoule5,
  FarineSemoule6,
  FarineSemoule7,
  FarineSemoule8,
  FarineSemoule9,
  FarineSemoule10,
  FarineSemoule11,
  FarineSemoule12,
  FarineSemoule13,
  FarineSemoule14,
} from "./assets/Farine & Semoule/index.js";
import {
  CouscousPâtes1,
  CouscousPâtes2,
  CouscousPâtes3,
  CouscousPâtes4,
  CouscousPâtes5,
  CouscousPâtes6,
  CouscousPâtes7,
  CouscousPâtes8,
  CouscousPâtes9,
  CouscousPâtes10,
  CouscousPâtes11,
  CouscousPâtes12,
  CouscousPâtes13,
  CouscousPâtes14,
  Huilemargarine1,
  Huilemargarine2,
  Huilemargarine3,
  Huilemargarine4,
  Huilemargarine5,
} from "./assets/Couscous & Pâtes/index";
import {
  SelSucre1,
  SelSucre2,
  SelSucre3,
  SelSucre4,
  SelSucre5,
  SelSucre9,
  SelSucre10,
  SelSucre11,
  SelSucre12,
  SelSucre13,
  SelSucre14,
  SelSucre15,
  SelSucre16,
  SelSucre17,
  SelSucre18,
  SelSucre19,
  SelSucre20,
  SelSucre21,
  SelSucre22,
  SelSucre23,
  SelSucre24,
  SelSucre25,
  SelSucre26,
  SelSucre27,
} from "./assets/Sel & Sucre/index";
import {
  LaitCafé1,
  LaitCafé2,
  LaitCafé3,
  LaitCafé4,
  LaitCafé5,
  LaitCafé6,
  LaitCafé7,
  LaitCafé8,
  LaitCafé9,
  LaitCafé10,
  LaitCafé11,
  LaitCafé12,
  LaitCafé13,
  LaitCafé14,
  LaitCafé15,
  LaitCafé16,
  LaitCafé17,
} from "./assets/Lait & Café/index";
import {
  FruitsSecs1,
  FruitsSecs2,
  FruitsSecs3,
  FruitsSecs4,
  FruitsSecs5,
  FruitsSecs6,
  FruitsSecs7,
  FruitsSecs8,
  FruitsSecs9,
  FruitsSecs10,
  FruitsSecs11,
  FruitsSecs12,
} from "./assets/Fruits secs/index";
import {
  Conserves1,
  Conserves2,
  Conserves3,
  Conserves4,
  Conserves5,
  Conserves6,
  Conserves7,
  Conserves8,
  Conserves9,
  Conserves10,
  Conserves11,
  Conserves12,
  Conserves13,
  Conserves14,
  Conserves15,
  Conserves16,
  Conserves17,
  Conserves18,
  Conserves19,
  Conserves20,
  Conserves21,
  Conserves22,
  Conserves23,
  Conserves24,
  Conserves25,
  Conserves26,
  Conserves27,
  Conserves28,
  Conserves29,
  Conserves30,
  Conserves31,
  Conserves32,
  Conserves33,
  Conserves34,
  Conserves35,
  Conserves36,
  Conserves37,
  Conserves38,
  Conserves39,
  Conserves40,
  Conserves41,
} from "./assets/Conserves/index";
import {
  Boissons1,
  Boissons2,
  Boissons3,
  Boissons4,
  Boissons5,
  Boissons6,
  Boissons7,
} from "./assets/Boissons/index";
import {
  Sauces1,
  Sauces2,
  Sauces3,
  Sauces4,
  Sauces5,
  Sauces6,
  Sauces7,
  Sauces8,
  Sauces9,
  Sauces10,
  Sauces11,
  Sauces12,
  Sauces13,
  Sauces14,
  Sauces15,
  Sauces16,
  Sauces17,
  Sauces18,
  Sauces19,
  Sauces20,
  Sauces21,
  Sauces22,
  Sauces23,
  Sauces24,
  Sauces25,
  Sauces26,
  Sauces27,
  Sauces28,
  Sauces29,
  Sauces30,
  Sauces31,
  Sauces32,
  Sauces33,
  Sauces34,
  Sauces35,
  Sauces36,
  Sauces37,
  Sauces38,
  Sauces39,
  Sauces40,
  Sauces41,
  Sauces42,
  Sauces43,
} from "./assets/Sauces/index";
import {
  Détergents1,
  Détergents2,
  Détergents3,
  Détergents4,
  Détergents5,
  Détergents6,
  Détergents7,
  Détergents8,
  Détergents9,
  Détergents10,
  Détergents11,
  Détergents12,
} from "./assets/Détergents/index";
import {
  lentilles,
  poisChiches,
  detergents,
  BourghoulFrikFêve,
  farine,
  semoule,
  riz,
  Arachides,
  ConservesBocal,
  ConservesMétal,
  Couscous,
  flan,
  cafe,
  Fruitssec,
  GammePâtissière,
  Haricots,
  Huile,
  lait,
  olive,
  Pâtes,
  PoisCassés,
  SaucesBocal,
  SaucesPET,
  Sel,
  Sucre,
  Tomate,
  Boissons,
  Eau,
} from "./assets/productReveu/index";

export const data = [
  {
    id: 1,
    category: "Légumes Secs",
    title: "Lentilles",
    discription:
      "Découvrez notre sélection de lentilles de qualité supérieure, comprenant la Lentille Royale, la Lentille Richli et la Lentille Corail. Ces légumineuses savoureuses et riches en nutriments sont parfaites pour une alimentation saine et équilibrée.",
    imgs: [
      LegumesSecs11,
      LegumesSecs3,
      LegumesSecs10,
      LegumesSecs4,
      LegumesSecs2,
      LegumesSecs1,
    ],
    bg: lentilles,
    Gamme: [
      "Lentille royale 1Kg",
      "Lentille royale 500g",
      "Lentille Richli 500g",
      "Lentille royale étuis 500g",
      "Lentille corail 1kg",
      "Lentille corail 500g",
    ],
  },
  {
    id: 2,
    category: "Légumes Secs",
    title: "Pois Chiches",
    discription:
      "Découvrez notre sélection de pois chiches de qualité supérieure, provenant du Mexique et des États-Unis. Ces légumineuses sont riches en protéines et en fibres, parfaites pour une alimentation saine et équilibrée.",
    imgs: [
      LegumesSecs8,
      LegumesSecs9,
      LegumesSecs7,
      LegumesSecs6,
      LegumesSecs5,
    ],
    bg: poisChiches,
    Gamme: [
      "Pois chiche 1Kg",
      "Pois chiche Bladi 500g",
      "Pois chiche mexicain étuis 500g",
      "Pois chiche 500g",
      "Pois chiche USA 1Kg",
    ],
  },
  {
    id: 3,
    category: "Légumes Secs",
    title: "Riz",
    bg: riz,
    discription:
      "Découvrez notre sélection de riz de qualité supérieure, comprenant du riz thaï, du riz blanc, du riz noir thaï, du risotto et du riz basmati blanc. Parfait pour toutes vos recettes culinaires.",
    imgs: [
      LegumesSecs14,
      LegumesSecs13,
      LegumesSecs18,
      LegumesSecs19,
      LegumesSecs12,
      LegumesSecs22,
      LegumesSecs21,
      LegumesSecs20,
      LegumesSecs16,
      LegumesSecs15,
      LegumesSecs17,
      LegumesSecs27,
      LegumesSecs26,
      LegumesSecs24,
      LegumesSecs23,
      LegumesSecs25,
    ],
    Gamme: [
      "Riz blanc 1Kg",
      "Riz blanc 500g",
      "Riz étuvé 1Kg",
      "Riz étuvé 500g",
      "Riz Thaï blanc 1Kg",
      "Riz Thaï blanc 500g",
      "Riz Thaï étuvé 1Kg",
      "Riz Thaï étuvé 500g",
      "Riz Thaï noir étuis 500Kg",
      "Riz Thaï noir 1Kg",
      "Risotto 1Kg",
      "Riz Basmati blanc 500g",
      "Riz Basmati blanc 1Kg",
      "Riz Basmati étuvé étuis 500g",
      "Riz Basmati étuvé 500g",
      "Riz Basmati étuvé 1Kg",
    ],
  },
  {
    id: 4,
    category: "Légumes Secs",
    title: "Haricots",
    bg: Haricots,
    discription:
      "Découvrez notre sélection de haricots de qualité supérieure, comprenant les variétés Haricot fort, Haricot rouge et Haricot blanc Alberta. Des ingrédients savoureux et nutritifs pour vos plats préférés",
    imgs: [
      LegumesSecs34,
      LegumesSecs35,
       LegumesSecs36,
       LegumesSecs37,
       LegumesSecs38,
       LegumesSecs30,
      LegumesSecs28,
      LegumesSecs29,
    ],
    Gamme: [
      "Haricot fort 1Kg",
      "Haricot fort 500g",
      "Haricot rouge 1Kg",
      "Haricot rouge 500g",
      "Haricot blanc fort 500g",
      "Haricot blanc petit 1Kg",
      "Haricot blanc petit 500g",
      "Haricot blanc Alberta 1Kg",
    ],
  },
  {
    id: 5,
    category: "Légumes Secs",
    title: "Petits Pois Cassés",
    bg: PoisCassés,
    discription:
      "Ces Petits Pois sont cultivés avec soin pour garantir leur fraîcheur et leur saveur délicieuse. Ils sont parfaits pour accompagner vos plats préférés et ajouter une touche de couleur à vos assiettes.",
    imgs: [LegumesSecs33, LegumesSecs31, LegumesSecs32],
    Gamme: [
      "Petits Pois 1kg",
      "Petits pois cassés 1Kg",
      "Petits pois cassés 500g",
    ],
  },
  {
    id: 6,
    category: "Farine & Semoule",
    discription:
      "Notre gamme de farines comprend de la farine de pizza pour une pâte croustillante, de la farine pour des pains et pâtisseries moelleux, ainsi que de la farine de gâteaux pour des desserts légers et aérés.",
    title: "Farine",
    imgs: [
      FarineSemoule3,
      FarineSemoule2,
      FarineSemoule9,
      FarineSemoule1,
      FarineSemoule5,
    ],
    bg: farine,
    Gamme: [
      "Farine de pizza 1Kg",
      "Farine de gâteaux 1Kg",
      "Farine 1Kg",
      "Farine 5Kg",
      "Farine T45 5Kg",
    ],
  },
  {
    id: 7,
    category: "Farine & Semoule",
    discription:
      "La semoule moyenne et la farine de pois chiche sont des ingrédients essentiels pour la cuisine méditerranéenne. Utilisez-les pour préparer des plats délicieux et sains tels que le couscous, les falafels et les pâtisseries traditionnelles.",
    title: "Semoule",
    imgs: [FarineSemoule8,FarineSemoule6, FarineSemoule7,  FarineSemoule4],
    bg: semoule,
    Gamme: [
      "Semoule moyenne 2Kg",
      "Semoule moyenne 1Kg",
      "Semoule d'orge moyenne 1Kg",
      "Garantita Farine de pois chiche 400g",
    ],
  },
  {
    id: 8,
    category: "Farine & Semoule",
    discription:
      "Bourghoul est un mélange d épices traditionnelles du Moyen-Orient, parfait pour donner une saveur authentique à vos plats. Utilisez-le pour assaisonner les viandes, les légumes et les ragoûts.",
    title: "Bourghoul, Frik & Fêve ",
    imgs: [
      FarineSemoule14,
      FarineSemoule11,
      FarineSemoule12,
      FarineSemoule13,
      FarineSemoule10,
    ],
    bg: BourghoulFrikFêve,
    Gamme: [
      "Frik 500g",
      "Frik 400g",
      'Bourghoul gros 500g',
      "Bourghoul 500g",
      "Fèves concassées 500g",
    ],
  },
  {
    id: 9,
    category: "Couscous & Pâtes",
    discription:
      "Découvrez notre gamme de couscous de qualité supérieure, comprenant du couscous moyen, du couscous fin et du couscous assaisonné. Parfait pour des plats savoureux et faciles à préparer",
    title: "Couscous ",
    bg: Couscous,
    imgs: [
      CouscousPâtes1,
      CouscousPâtes6,
      CouscousPâtes7,
      CouscousPâtes8,
      CouscousPâtes9,
      CouscousPâtes10,
      CouscousPâtes11,
     
    ],
    Gamme: [
      "Couscous moyen 1Kg",
      "Couscous moyen 500g",
      "Couscous moyen 400g",
      "Couscous fin 1Kg",
      "Golden couscous moyen 1Kg",
      "Couscous assaisonné 500g",
      "Couscous assaisonné 1Kg",
    ],
  },
  {
    id: 10,
    category: "Couscous & Pâtes",
    discription:
      "Découvrez les pâtes Golden Pasta spaghetti et coude, fabriquées à partir des meilleurs ingrédients pour une texture parfaite et un goût délicieux. Idéales pour toutes vos recettes de pâtes préférées.",
    title: "Pâtes ",
    bg: Pâtes,
    imgs: [
      CouscousPâtes14,
      CouscousPâtes2,
      CouscousPâtes12,
      CouscousPâtes3,
      CouscousPâtes4,
      CouscousPâtes5,
    ],
    Gamme: [
      "Golden Pasta spaghetti 500g",
      "Golden Pasta coude 4 500g",
      "Golden Pasta coude 6 500g",
      "Golden Pasta tlitli 500g",
      "Golden Pasta vermicelle 500g",
      "Golden Pasta petit plomb 500g",
    ],
  },
  {
    id: 11,
    category: "Sel & Sucre",
    discription:
      "La gamme Pâtissière est une sélection de produits de qualité supérieure pour les professionnels de la pâtisserie. Elle comprend des ingrédients de première qualité pour des créations culinaires exceptionnelles.",
    title: "Gamme Pâtissière ",
    bg: GammePâtissière,
    imgs: [SelSucre3, SelSucre2, SelSucre1,  SelSucre4],
    Gamme: [
      "Amidon de maïs 500g",
      "Noix de coco 200g",
      "Noix de coco étuis 200g",
      "Poudre de cacao 125g",
    ],
  },
  {
    id: 12,
    category: "Sel & Sucre",
    discription:
      "Notre gamme de sucres comprend du sucre blanc, du sucre roux et du sucre glace de qualité supérieure. Parfaits pour la cuisine et la pâtisserie, ces sucres apporteront une touche de douceur à vos créations culinaires.",
    title: "Sucre ",
    bg: Sucre,
    imgs: [
      SelSucre5,
      SelSucre18,
      SelSucre17,
      SelSucre16,
      SelSucre15,
      SelSucre19,
      SelSucre20,
      SelSucre21,
      SelSucre22,
      SelSucre9,
      SelSucre10,
      SelSucre11,
      SelSucre12,
      SelSucre13,
      SelSucre14,
    ],
    Gamme: [
      "Sucre blanc cristallisé 5Kg",
      "Sucre blanc cristallisé 1Kg",
      "Sucre blanc cristallisé 500g",
      "Sucre blanc cristallisé étuis 1Kg",
      "Boite de sucre stick 150 pièces 6g/Pièce",
      "Sucre raffiné moulé étuis 750g",
      "Sucre roux moulé étuis 750g",
      "Sucre roux étuis 1Kg",
      "Sucre roux 1Kg",
      "Boite de sucre stick 125 pièces 8g/Pièce",
      "Sucre blanc cristallisé 1Kg",
      "Sucre raffiné moulé 700g",
      "Sucre glace 700g",
      "Sucre glace 500g",
      "Sucre glace 500g",
    ],
  },
  {
    id: 13,
    category: "Sel & Sucre",
    discription:
      "Le sel de table est un condiment indispensable pour assaisonner vos plats. Notre sel de qualité supérieure est finement moulu et enrichi en minéraux pour une saveur et une texture optimales.",
    title: "Sel de table",
    bg: Sel,
    imgs: [SelSucre26, SelSucre27, SelSucre23, SelSucre24, SelSucre25],
    Gamme: [
      "Sel de table extra fin 1kg",
      "Sel de table fin iodé 1kg",
      "Sel de table fin iodé 900g",
      "Sel de table iodé étuis 500g",
      "Sel de table iodé étuis 1Kg",
    ],
  },
  {
    id: 14,
    category: "Lait & Café",
    discription:
      "Le lait en poudre est un produit pratique et polyvalent qui peut être utilisé pour la préparation de boissons chaudes ou froides, de desserts et de plats cuisinés. Il est facile à stocker et a une longue durée de conservation.",
    title: "Lait en poudre ",
    bg: lait,
    imgs: [
      LaitCafé1,
      LaitCafé2,
      LaitCafé3,
      LaitCafé4,
      LaitCafé5,
      LaitCafé6,
      LaitCafé7,
      LaitCafé8,
    ],
    Gamme: [
      "Lait Splash écrémé étuis 500g",
      "Lait Splash étuis 500g",
      "Lait Splash 500g",
      "Lait Splash 125g",
      "Lait Thika étuis 500g",
      "Lait Thika 500g",
      "Lait Splash 125g",
      "Préparation fromagère 170g",
    ],
  },
  {
    id: 15,
    category: "Lait & Café",
    discription:
      "Découvrez notre gamme de cafés de qualité supérieure, comprenant du café moulu, du café en grain et du café instantané. Profitez d un goût riche et intense à chaque tasse",
    title: "Café",
    bg: cafe,
    imgs: [
      LaitCafé10,
      LaitCafé9,
      LaitCafé17,
       LaitCafé13,
      LaitCafé14,
      LaitCafé12,
      LaitCafé11,
      LaitCafé15,
      LaitCafé16,
    ],
    Gamme: [
      "Café moulu 100% Robusta 250g",
      "Café moulu 100% Robusta 5Kg",
      "Café en grain sac 5Kg",
      "Café classique instantané 50g",
      "Café Gold instantané 50g",
      "Café instantané 150 pièces 2g/Pièce",
      "Café instantané 50g",
      "Thé vert étuis 250g",
      'Café moulu 100% Robusta'
    ],
  },
  {
    id: 16,
    category: "Fruits secs",
    discription:
      "Découvrez notre sélection de Pruneaux d Agen et Raisins secs Premium, soigneusement choisis pour leur qualité supérieure. Savourez leur goût délicieux et leur texture moelleuse, parfaits pour une collation saine et gourmande.",
    title: "Fruits secs",
    bg: Fruitssec,
    imgs: [ FruitsSecs2,FruitsSecs1, FruitsSecs3, FruitsSecs4, FruitsSecs5],
    Gamme: [
      "Pruneaux d’Agen 500g",
      "Abricot sec 250g",
      "Raisin sec Premuim 500g",
      "Raisin sec 500g",
      "Raisin sec 250g",
    ],
  },
  {
    id: 17,
    category: "Fruits secs",
    discription:
      "décortiquées et grillées à sec, sans sel ajouté. Une collation saine et savoureuse pour les amateurs de noix. Emballage pratique pour une consommation nomade.",
    title: "Arachides",
    bg: Arachides,
    imgs: [FruitsSecs8 , FruitsSecs7, FruitsSecs6],
    Gamme: [
      "Arachides coques grillées 200g",
      "Arachides coques grillées 250g",
      "Arachides blanches décortiquées 500g",
    ],
  },
  {
    id: 18,
    category: "Fruits secs",
    discription:
      "de caramel onctueux et délicieux, préparé avec des ingrédients de qualité supérieure pour une expérience gustative inoubliable. Parfait pour les desserts ou les collations.",
    title: "Flan",
    bg: flan,
    imgs: [ FruitsSecs12, FruitsSecs9, FruitsSecs10, FruitsSecs11],
    Gamme: [
      "Flan caramel +50 g Caramel 110g",
      "Flan vanille 110g",
      "Flan fraise 110g",
      "Flan chocolat 110g",
    ],
  },
  {
    id: 19,
    category: "Conserves",
    discription:
      "Découvrez notre délicieuse sauce tomate à la harissa, idéale pour donner une touche épicée à vos plats. Fabriquée à partir d ingrédients de qualité supérieure, cette sauce est un véritable régal pour les papilles.",
    title: "Tomate & Harissa",
    bg: Tomate,
    imgs: [
      Conserves4,
      Conserves5,
      Conserves2,
      Conserves3,
      Conserves6,
      Conserves1,
    ],
    Gamme: [
      "Double concentré de tomate 28% 800g",
      "Double concentré de tomate 28% 400g",
      "Harissa 380g",
      "Harissa 135g",
      "Double concentré de tomate 28% 800g",
      "Double concentré de tomate 28% 400g",
    ],
  },
  {
    id: 20,
    category: "Conserves",
    discription:
      "Découvrez notre conserve métallique de champignons de Paris entiers et de petits pois doux, parfaitement préparés pour une utilisation facile et rapide en cuisine. Un choix de qualité pour vos plats savoureux.",
    title: "Conserves Métal",
    bg: ConservesMétal,
    imgs: [
      Conserves12,
      Conserves13,
      Conserves15,
      Conserves14,
      Conserves7,
      Conserves9,
      Conserves10,
      Conserves11,
    ],
    Gamme: [
      "Champignon de Paris entier 400g",
      "Champignon de Paris tranches 400g",
      "Petits pois doux 400g",
      "Pois chiche 400g",
      "Fèves cuites 400g",
      "Le Maïs 380g",
      "Le Maïs 230g",
      "Le Maïs 190g",
    ],
  },
  {
    id: 21,
    category: "Conserves",
    discription:
      "Les conserves Bocal sont des produits de qualité supérieure, préparés avec soin et emballés dans des bocaux en verre. Ils sont parfaits pour les repas rapides et pratiques, tout en conservant la saveur et la fraîcheur des ingrédients.",
    title: "Conserves Bocal",
    bg: ConservesBocal,
    imgs: [
      Conserves17,
      Conserves16,
      Conserves18,
      Conserves19,
      Conserves8,
      Conserves20,
      Conserves26,
      Conserves27,
      Conserves24,
      Conserves25,
      Conserves30,
      Conserves29,
      Conserves21,
      Conserves31,
      Conserves32,
      Conserves34,
      Conserves33,
      Conserves28,
      Conserves23,
      Conserves22,
    ],
    Gamme: [
      "Pois chiche cuit 570g",
      "Pois chiche cuit 570g",
      "Pois chiche cuit 360g",
      "Pois chiche cuit 360g",
      "Pois chiche Bladi 360g",
      "Haricot tacheté 360g",
      "Haricot blanc 370g",
      "Haricot blanc 580g",
      "Petit pois doux 360g",
      "Petit pois doux 540g",
      "Piment mariné 635g",
      "Fonds d'artichauts 700g",
      "Cornichonsau vinaigre 360g",
      "Maïs mini épisau vinaigre 360g",
      "Piment mariné 360g",
      "Champignon de Paris entier 250g",
      "Champignon de Paris entier 250g",
      "Cornichons moyen 200g",
      "Oignons Grelots au vinaigre 250g",
      "Câpres au vinaigre 250g",
    ],
  },
  {
    id: 22,
    category: "Conserves",
    discription:
      "Les conserves d'olives sont un choix parfait pour les amateurs d'olives qui souhaitent profiter de leur saveur toute l'année. Ces olives sont soigneusement sélectionnées et conservées dans de l'huile d'olive de qualité supérieure pour une expérience gustative inoubliable.",
    title: "Olives",
    bg: olive,
    imgs: [
      Conserves38,
      Conserves41,
      Conserves37,
      Conserves39,
      Conserves40,
      Conserves35,
      Conserves36,
    ],
    Gamme: [
      "Olives vertes dénoyautées 5kg",
      "Olives vertes dénoyautées 635g",
      "Olives vertes 720g",
      "Olives vertes 720g",
      "Olives vertes à l'ail 720g",
      "Olives cocktail 540g",
      "Olives cocktail à l’huile 540g",
      "Olives vertes rondelles 360g",
      "Olives cocktail 345g",
      "Olives cocktail à l’huile 380g",
      "Olives vertes dénoyautées 800g",
    ],
  },
  {
    id: 23,
    category: "Boissons",
    discription:
      "Découvrez notre gamme de boissons rafraîchissantes Jus Suco et Fresh Pop. Des saveurs fruitées et pétillantes pour une expérience gustative unique. Parfaites pour se désaltérer en toute occasion.",
    title: "Boissons",
    imgs: [Boissons1, Boissons2, Boissons3, Boissons4, Boissons5],
    bg: Boissons,
    Gamme: [
      "Fresh Pop 55ml",
      "Jus Suco cocktail 200ml",
      "Jus Suco orange 200ml",
      "Jus Suco cocktail 125ml",
      
      "Jus Suco orange 125ml",
    ],
  },
  {
    id: 24,
    category: "Boissons",
    discription:
      "Découvrez notre eau minérale naturelle, source de vitalité et de bien-être. Embouteillée directement à la source, elle est riche en minéraux et offre une pureté incomparable. Essayez-la dès maintenant !",
    title: "Eau",
    bg: Eau,
    imgs: [Boissons6, Boissons7],
    Gamme: ["Eau minérale Guerioune 1.5L", "Eau minérale Guerioune 0.5L"],
  },
  {
    id: 25,
    category: "Sauces",
    discription:
      "Les sauces PET sont des condiments de qualité supérieure, conditionnés dans des bouteilles en plastique PET résistantes et pratiques. Disponibles en plusieurs saveurs, elles sont parfaites pour accompagner vos plats préférés.",
    title: "Sauces PET",
    bg: SaucesPET,
    imgs: [
      Sauces19,
      Sauces16,
      Sauces17,
      Sauces2,
      Sauces18,
      Sauces21,
      Sauces15,
      Sauces12,
      Sauces3,
      Sauces4,
      Sauces7,
      Sauces9,
      Sauces10,
      Sauces11,
      Sauces14,
      Sauces20,
      Sauces5,
      Sauces6,
      Sauces8,
      
      Sauces1,
      
      Sauces13,
      
    ],
    Gamme: [
      "Sauce Algérienne 500g",
      "Ketchup Tomate 500g",
      "Moutarde de Dijon 510g",
        "Sauce Pita 900g",
      "Sauce Algérienne 900g",
      "Moutarde de Dijon 900g",
      "Ketchup Tomate 900g",
       "Sauce Mayonnaise 900g",
       "Sauce Algérienne 250g",
       "Sauce Algérienne 300g",
      "Sauce Pita 500g",
      'Moutarde de Dijon 310g',
      "Sauce Pita 250g",
      "Sauce Pita 300g", 
      "Moutarde de Dijon 900g",
      "Sauce Pita 900g", 
      "Sauce Mayonnaise 900g",
      "Ketchup Tomate 900g",
      "Sauce Andalouse 900g",
      "Ketchup Tomate 2,5Kg",
      "Sauce Mayonnaise 2,5Kg",
    ],
  },
  {
    id: 26,
    category: "Sauces",
    discription:
      "Découvrez notre gamme de sauces artisanales en bocal, préparées avec des ingrédients frais et de qualité. Parfaites pour accompagner vos plats préférés, elles apporteront une touche de saveur et d'originalité à votre cuisine.",
    title: "Sauces Bocal",
    bg: SaucesBocal,
    imgs: [
      Sauces41,
      Sauces40,
      Sauces39,
      Sauces38,
      Sauces37,
      Sauces33,
      Sauces22,
      Sauces42,
      Sauces43,
      Sauces23,
      Sauces24,
      Sauces25,
      
      Sauces26,
      
      Sauces27,
      Sauces28,
      Sauces29,
      Sauces30,
      Sauces31,
      Sauces32,
     
      Sauces34,
      Sauces35,
      Sauces36,
      
    ],
    Gamme: [
      "Sauce Pita 350g",
      "Sauce Samouraï 350g",
      "Sauce andalouse 350g",
      "Ketchup Tomate 360g",
      "Sauce Mayonnaise 350g",
      "Moutarde de Dijon 360g",
      "Moutarde de Dijon 690g",
      "Sauce hamburger 350g",
      "Sauce curry 350g",
      "Sauce 3 poivres 350g",
      "Sauce algérienne 350g",
      "Sauce algérienne 180g",
      "Sauce Mayonnaise 180g",
      "Moutarde de Dijon 190g",
      "Harissa traditionnelle 185g",
       "Saucessticks 10g 150Pcs",
      "Sauce andalouse 10g",
      "Sauce algérienne 10g",
      "Sauce Mayonnaise 10g",
      "Moutarde de Dijon 10g",
      "Sauce Samouraï 10g",
      "Ketchup tomate 10g",
    ],
  },
  {
    id: 27,
    category: "Détergents",
    discription:
      "professionnels haut de gamme pour un nettoyage efficace et en profondeur. Formulés pour répondre aux besoins spécifiques des entreprises, ils garantissent une propreté impeccable et une hygiène irréprochable.",
    title: "Détergents",
    imgs: [
      Détergents2,
      Détergents3,
      Détergents5,
      Détergents4,
      Détergents8,
      Détergents7,
      Détergents6,
      Détergents1,
      Détergents9,
      Détergents10,
      Détergents11,
      Détergents12,
    ],
    bg: detergents,
    Gamme: [
      "Poudre usage machine 2.5kg",
      "Poudre usage machine 1.6kg",
      "Poudre multiusage parfumée 600g",
      "Poudre multiusage parfumée 350g",
      "Poudre multiusage parfumée étuis 3kg",
      "Poudre multiusage parfumée étuis 1.5kg",
      "Poudre multiusage parfumée étuis 500g",
      "Poudre multiusage parfumée étuis 400g",
      "Eau de javel 5L",
      "Eau de javel 925ml",
      "Savon de Marseille 400g",
      "Savon 400g",
      "Savon 250g",
    ],
  },
  {
    id: 28,
    category: "Couscous & Pâtes",
    discription:
      "L'huile et la margarine sont deux produits essentiels pour la cuisine. L'huile est idéale pour la friture et la cuisson, tandis que la margarine est parfaite pour la pâtisserie et la cuisson à feu doux.",
    title: "Huile & margarine",
    bg: Huile,
    imgs: [
      Huilemargarine1,
      Huilemargarine2,
      Huilemargarine3,
      Huilemargarine5,
      Huilemargarine4,
    ],
    Gamme: [
      "Margarine végétale 500g",
      "Margarine végétale 250g",
      "L’huile végétale 5L",
      "L’huile végétale 2L",
      "L’huile végétale 1L",
    ],
  },
];
