import { motion } from "framer-motion";
import React from "react";
import { MdArrowRightAlt, MdVisibility } from "react-icons/md";
import { Link } from "react-router-dom";

function SingelProduct({ data }) {
  return (
    <Link
      to={`/ProductInfo/${data.id}`}
      className="w-full relative h-fit sm:h-[520px] border rounded-lg group overflow-hidden  cursor-pointer transition-all duration-300 ease-in-out hover:shadow-2xl"
    >
      <motion.div
        key={data.id}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className=" absolute top-0 right-0 p-3 opacity-[1] z-10 md:opacity-0 md:-z-10 group-hover:opacity-100 group-hover:z-10 transition-all duration-300 ease-in-out">
          <MdVisibility className="text-fcolor text-[30px] " />
        </div>
        <div className="w-full overflow-hidden transition-all duration-500 ease-in-out">
          <img
            src={data.bg}
            alt="product-img"
            className="w-full h-[200px] object-contain    group-hover:scale-150 transition-all duration-500 ease-in-out"
          />
        </div>
        <div className="border-t">
          <div className=" p-4">
            <h3 className="font-bold text-2xl text-center mb-4">
              {data.title}
            </h3>
            <p className="text-center text-[17px] h-fit sm:h-[175px] " style={{fontStyle: "italic"}}>
              {data.discription}
            </p>
          </div>
          <div className="my-4 w-full flex items-center justify-center gap-3 group-hover:gap-6 transition-all duration-300 ease-in-out text-fcolor">
            <p className="text-[18px] font-semibold">plus d'informations</p>
            <MdArrowRightAlt className="text-[30px] transition-all duration-300 " />
          </div>
        </div>
      </motion.div>
    </Link>
  );
}

export default SingelProduct;
