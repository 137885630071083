import React, { useEffect } from "react";
import { data } from "../productsData";
import SingelProduct from "../components/SingelProduct";
import {
  Boissons,
  Conserves,
  Détergents,
  FruitsSecs,
  LEGUMESSEC,
  Sauces,
  couscousEtPates,
  farineEtSemoule,
  laitEtCafe,
  selEtSucre,
  tousLesProduit,
} from "../assets/category/index";
import { useState } from "react";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
const categories = [
  {
    id: 1,
    title: "Tous Les Produit",
    img: tousLesProduit,
  },
  {
    id: 2,
    title: "Légumes Secs",
    img: LEGUMESSEC,
  },
  {
    id: 3,
    title: "Farine & Semoule",
    img: farineEtSemoule,
  },
  {
    id: 4,
    title: "Couscous & Pâtes",
    img: couscousEtPates,
  },
  {
    id: 5,
    title: "Sel & Sucre",
    img: selEtSucre,
  },
  {
    id: 6,
    title: "Lait & Café",
    img: laitEtCafe,
  },
  {
    id: 7,
    title: "Fruits secs",
    img: FruitsSecs,
  },
  {
    id: 8,
    title: "Conserves",
    img: Conserves,
  },
  {
    id: 9,
    title: "Boissons",
    img: Boissons,
  },
  {
    id: 10,
    title: "Sauces",
    img: Sauces,
  },
  {
    id: 11,
    title: "Détergents",
    img: Détergents,
  },
];

function AllProducts() {
  useEffect(() => {
    Aos.init();
  }, []);
  const [filteredData, setFilteredData] = useState([]);
  const [category, setCategory] = useState("Tous Les Produit");
  useEffect(() => {
    if (category === "Tous Les Produit") {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((ele) => ele.category === category));
    }
  }, [category]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pt-[100px] min-h-[100vh] px-3 md:px-9 mb-10">
      <h1
        data-aos-duration="1500"
        data-aos="zoom-out"
        className=" text-3xl md:text-5xl w-fit pb-5 mb-[50px] mx-auto text-black font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-fcolor "
      >
        Tous les produits
      </h1>
      <motion.div
        transition={{ duration: 1, ease: "linear" }}
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        className="flex items-center w-full gap-2 justify-center flex-wrap mb-10"
      >
        {categories.map((ele) => {
          return (
            <div
              onClick={() => setCategory(ele.title)}
              className={`md:w-[100px] md:h-[110px] w-[100px] h-[100px]  rounded-lg flex flex-col gap-1 items-center justify-center hover:bg-black hover:text-white transition-all duration-300 ease-in-out cursor-pointer ${
                category === ele.title
                  ? "bg-black text-white"
                  : "bg-transparent text-black"
              }`}
              key={ele.id}
            >
              <img
                src={ele.img}
                alt={ele.title}
                className="md:w-[80px] h-[50px] w-[50px] md:h-[80px] object-contain "
              />
              <p className=" text-[13px] md:text-[13px] text-center ">
                {ele.title}
              </p>
            </div>
          );
        })}
      </motion.div>
      {filteredData && (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">
          {filteredData.map((ele) => {
            return <SingelProduct key={ele.id} data={ele} />;
          })}
        </div>
      )}
    </div>
  );
}

export default AllProducts;
