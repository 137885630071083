import React, { useEffect } from "react";
import banerbg from "../assets/baner.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

function BanerBg() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div
        data-aos-duration="1500"
        data-aos="flip-right"
        className="w-full h-[40vh] md:h-[65vh] relative"
      >
        <div className=" absolute top-0 left-0 w-full h-full bg-[#00000054]"></div>
        <img src={banerbg} className="w-full h-full" alt="" />
      </div>
    </div>
  );
}

export default BanerBg;
