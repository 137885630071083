import React, { useEffect } from "react";
import { MdHighQuality, MdShoppingBasket } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { SiWorldhealthorganization } from "react-icons/si";
import Aos from "aos";
import "aos/dist/aos.css";
function Features() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="w-full min-h-[80vh] pt-[80px] ">
      <h1
        data-aos-duration="1500"
        data-aos="zoom-out"
        className=" text-3xl md:text-5xl w-fit pb-5 mb-[80px] mx-auto text-black font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-fcolor "
      >
        Nos Caractéristiques
      </h1>
      <div className="px-3 md:px-9 bg-tcolor py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
          <div
            data-aos-duration="700"
            data-aos="zoom-in"
            className="flex flex-col  w-full py-3 px-2 bg-white rounded-xl"
          >
            <div className="flex justify-center items-center mb-3 ">
              <FaMoneyBillAlt className="text-[40px] text-fcolor " />
            </div>
            <h3 className=" font-semibold text-[20px] text-center text-gray-500 mb-3">
              Économisez de l'argent
            </h3>
            <p className="text-[17px] text-center text-gray-500">
              avec nous, vous pouvez économiser de l'argent et le dépenser pour
              plus de choses dont vous avez besoin
            </p>
          </div>
          <div
            data-aos-duration="1400"
            data-aos="zoom-in"
            className="flex flex-col  w-full py-3 px-2 bg-white rounded-xl  "
          >
            <div className="flex justify-center items-center mb-3 ">
              <MdHighQuality className="text-[40px] text-fcolor " />
            </div>
            <h3 className=" font-semibold text-[20px] text-center text-gray-500 mb-3">
              Milleure qualité
            </h3>
            <p className="text-[17px] text-center text-gray-500">
              Nous nous soucions de la satisfaction de nos clients plus que de
              nos profits. nous ferons tout pour vous
            </p>
          </div>
          <div
            data-aos-duration="2100"
            data-aos="zoom-in"
            className="flex flex-col  w-full py-3 px-2 bg-white rounded-xl  "
          >
            <div className="flex justify-center items-center mb-3 ">
              <MdShoppingBasket className="text-[40px] text-fcolor " />
            </div>
            <h3 className=" font-semibold text-[20px] text-center text-gray-500 mb-3">
              Variété de produits
            </h3>
            <p className="text-[17px] text-center text-gray-500">
              Nous avons une variété de produits différents. Le choix vous
              appartient
            </p>
          </div>
          <div
            data-aos-duration="2800"
            data-aos="zoom-in"
            className="flex flex-col  w-full py-3 px-2 bg-white rounded-xl  "
          >
            <div className="flex justify-center items-center mb-3 ">
              <SiWorldhealthorganization className="text-[40px] text-fcolor " />
            </div>
            <h3 className=" font-semibold text-[20px] text-center text-gray-500 mb-3">
              Santé et sécurité
            </h3>
            <p className="text-[17px] text-center text-gray-500">
              Le Groupe thika veut le meilleur pour ses clients. Donc, nous
              fournissons les produits les plus sains que nous pouvons faire
              pour vous
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
