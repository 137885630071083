import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { data } from "../productsData";
import "./infoProduct.css";
import { AnimatePresence, motion } from "framer-motion";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { MdClose } from "react-icons/md";
import { IoMdArrowRoundBack } from "react-icons/io";
import Aos from "aos";
import "aos/dist/aos.css";
import { GridLoader } from "react-spinners";
function ProductInfo() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [img, setImg] = useState(0);
  const [overlay, setOverlay] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    data.find((ele) => (+ele.id === +id ? setProduct(ele) : ""));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      {product ? (
        <div className="pt-[100px] px-3 md:px-9 mb-10 overflow-hidden">
          <AnimatePresence>
            {overlay && (
              <motion.div
                transition={{ duration: 0.5, ease: "easeInOut" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed top-0 left-0 w-full h-full bg-[#000000d1] z-[10000] flex items-center justify-center"
              >
                <img
                  src={product.imgs[img]}
                  alt="product-img"
                  className="md:w-[700px] h-[75%] w-[80%] md:h-[500px] object-contain bg-white rounded-2xl"
                />
                <div
                  onClick={() => setOverlay(false)}
                  className=" cursor-pointer z-50 flex justify-center group items-center absolute top-5 right-5 w-[40px] h-[40px] rounded-full bg-white"
                >
                  <MdClose className="text-3xl group-hover:text-fcolor transition-all duration-300 ease-in-out  " />
                </div>
                <p className="md:w-[700px] w-[80%] text-xl md:text-4xl font-semibold text-center absolute top-5 text-white ">
                  {product.Gamme[img]}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="flex flex-wrap">
            <div
              data-aos-duration="1500"
              data-aos="fade-right"
              className="flex flex-col w-full lg:w-[50%] gap-5 items-center relative"
            >
              <div
                onClick={() => setOverlay(true)}
                className="w-[35px] flex items-center group: justify-center h-[35px] transition-all duration-300 ease-in-out rounded-full bg-black hover:bg-fcolor absolute top-3 right-4 cursor-pointer"
              >
                <PiMagnifyingGlassBold className="text-[20px] text-white transition-all duration-300 ease-in-out" />
              </div>
              <img
                src={product.imgs[img]}
                alt="product-img"
                className="w-full h-[410px] object-contain border rounded-xl "
              />
              <div className="h-[120px] w-full flex items-center  gap-5 sc overflow-x-auto">
                {product.imgs.map((ele, index) => {
                  return (
                    <img
                      key={index}
                      src={ele}
                      alt=""
                      className={`w-[90px] h-[90px] object-contain rounded-xl cursor-pointer select-none transition-all duration-500 ease-in-out ${
                        index === img && " bg-[#e2061378]"
                      }`}
                      onClick={() => setImg(index)}
                    />
                  );
                })}
              </div>
            </div>
            <div
              data-aos-duration="1500"
              data-aos="fade-left"
              className=" w-full lg:w-[50%] min-h-[530px] mt-10 lg:mt-0 lg:px-10"
            >
              <div className="flex items-center gap-5 text-2xl ">
                <motion.h1
                  transition={{ duration: 1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className=" font-bold text-fcolor text-3xl"
                >
                  {product.Gamme[img]}
                </motion.h1>
                {/* <h1 className=" font-bold text-fcolor text-3xl">
                  {product.title}
                </h1> */}
              </div>
              <div className="flex items-center gap-10 text-xl mt-3">
                <p className=" font-semibold">catégorie:</p>
                <p className="">{product.category}</p>
              </div>

              <p
                className=" text-gray-500 text-xl mt-3 leading-loose"
                style={{ fontStyle: "italic" }}
              >
                <span
                  className="text-black font-semibold"
                  style={{ fontStyle: "normal" }}
                >
                  description
                </span>
                : {product.discription}
              </p>

              <div className="mt-3">
                <p className="text-xl font-semibold mb-1">Gammes:</p>
                <ul className="grid  md:grid-cols-2 gap-1">
                  {product.Gamme.map((ele, index) => {
                    return (
                      <li
                        className="text-lg flex items-center gap-2"
                        key={index}
                      >
                        <span className="w-[10px] h-[10px] rounded-full bg-fcolor"></span>
                        {ele}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <button
                onClick={() => navigate(-1)}
                className=" mt-10 w-[150px] h-[50px] rounded-lg mx-auto flex items-center justify-center gap-5 text-xl transition-all duration-300 ease-in-out hover:bg-fcolor hover:text-white border border-fcolor text-fcolor "
              >
                <IoMdArrowRoundBack />
                Retour
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[100vh] flex justify-center items-center">
          <GridLoader color="#e20613" size={20} />
        </div>
      )}
    </>
  );
}

export default ProductInfo;
