import React, { useEffect } from "react";
import groupThika1 from "../assets/groupThika1.jpg";
import groupThika2 from "../assets/groupThika2.jpg";
import groupThika3 from "../assets/groupThika3.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
function About() {
  useEffect(() => {
    Aos.init();

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pt-[100px] min-h-[100vh] px-3 md:px-9 mb-10 overflow-hidden">
      <h1
        data-aos-duration="1500"
        data-aos="zoom-out"
        className=" text-3xl md:text-5xl w-fit pb-5 mb-[80px] mx-auto text-black font-bold relative before:w-[50%] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:h-[4px] before:bg-fcolor ">
        Group Thika
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">
        <div data-aos="fade-right" className="w-full" data-aos-duration="2000">
          <img
            src={groupThika1}
            alt="groupThika"
            className="w-full rounded-xl h-full   "
          />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          className="w-full px-2 flex items-center justify-center"
        >
          <p className="text-base sm:text-[19px] leading-loose text-center md:text-start">
            L’entreprise THIKA est une entreprise familiale qui a été fondé en
            1994, zone d'activité Ain-Smara Constantine, dont le secteur
            d’activité est la distribution des produits alimentaire de grande
            consommation. En 2005, l’activité de l’entreprise a évolué vers le
            domaine de l'emballage des aliments et toutes sortes de produits, et
            a débuté avec l'emballage de sucre et des légumineuses. Et au vu des
            besoins du marché algérien, l’entreprise s'élargit à partir de 2011
            et ce grâce à des investissements dans le domaine de l’emballage et
            la production alimentaire, l’entreprise a donc pu aborder de
            nouveaux marchés tels que l’emballage de lait, sel et fruits secs
            ainsi que la production de boissons visant le bienêtre des enfants
            et ce en présentant un nouveau produit de haute qualité FRESH POP.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">
        <div
          data-aos-duration="2000"
          data-aos="fade-right"
          className="w-full px-2 flex items-center justify-center order-10 sm:order-1"
        >
          <p className="text-base sm:text-[19px] leading-loose text-center md:text-start">
            Ensuite, l’entreprise s’est lancée dans la conserverie alimentaire
            ce qui lui a permis de devenir un pole industriel dans la région
            ainsi que dans tout le territoire national avec une production qui
            fournit au marché algérien des produits locaux avec un meilleur
            rapport qualité/prix et donc à la portée de la classe moyennes.
            L’entreprise, ainsi contribue dans le développement local.
          </p>
        </div>
        <div
          data-aos="fade-left"
          className="w-full sm:order-10 order-1"
          data-aos-duration="2000"
        >
          <img
            src={groupThika3}
            alt="groupThika"
            className="w-full md:h-[361px] h-full rounded-xl "
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">
        <div data-aos="fade-right" className="w-full" data-aos-duration="2000">
          <img
            src={groupThika2}
            alt="groupThika"
            className="w-full rounded-xl md:h-[370px] h-full"
          />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          className="w-full px-2 flex items-center justify-center"
        >
          <p className="text-base sm:text-[19px] leading-loose text-center md:text-start">
            La transition vers l’exportation fut le prochain pas, et ce en se
            dirigeant vers les marchés africains et internationaux dès le début
            de 2018 sachant que l’entreprise possède un effectif composé
            d’approximativement 350 employés permanents et 360 employés
            temporaires auxquels vient s’ajouté un groupe de distributeur
            répondu sur tout le territoire national. Avec cela, la société a de
            grandes vues sur le domaine de l’évolution et de la durabilité, ce
            dans le but de devenir l’un des piliers économique de l’Algérie
            construit sur une base : la protection du pouvoir d’achat du citoyen
            algérien.
          </p>
        </div>
      </div>
      <p className=" text-2xl sm:text-4xl text-center font-bold text-fcolor ">
        Thika toujours avec la famille 💗
      </p>
    </div>
  );
}

export default About;
