import React from "react";

function LastFooter() {
  return (
    <div className="px-3 md:px-9 py-5 bg-lcolor flex justify-center text-xl">
      <p className="text-white text-center">
        Group Thika<span className=" animate-pulse">💗</span> © Copyright{" "}
        <span className=" font-bold text-fcolor">
          {new Date().getFullYear()}
        </span>{" "}
        All rights reserved
      </p>
    </div>
  );
}

export default LastFooter;
