import img1 from "../assets/p1.png";
import img2 from "../assets/p2.png";
import img3 from "../assets/p3.jpg";
import img4 from "../assets/p4.png";
import img5 from "../assets/p5.jpg";
import sp1 from "../assets/sp1.jpg";
import sp2 from "../assets/sp2.jpg";
import sp3 from "../assets/sp3.jpg";
import sp4 from "../assets/sp4.png";
import sp5 from "../assets/sp5.png";

import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./hero.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
// favicon.ico
const data = [
  {
    id: 1,
    img: img1,
    sp: sp1,
  },
  {
    id: 2,
    img: img2,
    sp: sp2,
  },
  {
    id: 3,
    img: img3,
    sp: sp3,
  },
  {
    id: 4,
    img: img4,
    sp: sp4,
  },
  {
    id: 5,
    img: img5,
    sp: sp5,
  },
];

function HeroSection() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="w-full h-[100vh] relative  ">
      <div className=" absolute top-0 left-0 w-full h-full z-[0] bg-[#00000075]"></div>
      <div className=" absolute top-0 left-0 w-full h-full z-[10] flex justify-center items-center">
        <div className="md:text-start text-center overflow-hidden">
          <h1
            data-aos-duration="2000"
            data-aos="fade-right"
            className="md:text-[50px] text-[30px] justify-center md:justify-start text-white mb-[20px] font-semibold md:font-bold flex items-center"
          >
            Bienvenue sur notre Site <p className="animate-wiggle">👋</p>
          </h1>

          <p
            data-aos-duration="2300"
            data-aos="fade-left"
            className="text-white text-[20px] md:text-[25px] mb-[18px]"
          >
            avec nous, vous pouvez trouver le produit parfait pour vous et votre
            famille
          </p>
          <p
            data-aos-duration="2600"
            data-aos="fade-up"
            className="text-white text-[20px] md:text-[25px] mb-[18px]"
          >
            Votre confort est notre objectif{" "}
            <span className=" animate-pulse">💗</span>
          </p>
          <Link
            data-aos-duration="3000"
            data-aos="zoom-in"
            to="AllProducts"
            className=" md:mx-0 mx-auto w-[200px] h-[50px] flex items-center justify-center text-white text-[18px] font-semibold border border-white rounded-lg hover:bg-white hover:text-fcolor  transition-all duration-300 ease-in-out uppercase"
          >
            voir nos produits
          </Link>
        </div>
      </div>
      <div className=" hidden md:block">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {data.map((ele) => {
            return (
              <SwiperSlide className="w-full h-full relative  " key={ele.id}>
                <img src={ele.img} alt="hero-img" className="w-full h-full " />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="block md:hidden">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper block md:hidden "
        >
          {data.map((ele) => {
            return (
              <SwiperSlide className="w-full h-full relative  " key={ele.id}>
                <img src={ele.sp} alt="hero-img" className="w-full h-full " />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default HeroSection;
