import React, { useState } from "react";
import logo from "../assets/logo thika.png";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaFacebook, FaInstagram } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { FcHome, FcShop, FcInfo, FcAssistant } from "react-icons/fc";
import { AnimatePresence, motion } from "framer-motion";

function Navebar() {
  const [showMenu, setShowMenu] = useState(false);
  const [color, setColor] = useState(false);
  window.addEventListener("scroll", (e) => {
    if (window.scrollY >= 70) {
      setColor(true);
    } else {
      setColor(false);
    }
  });
  return (
    <>
      {/* /// desktop/// */}
      <nav
        className={`h-[70px] fixed top-0 left-0 z-[1000] px-3 md:px-9  w-full drop-shadow-lg md:block hidden transition-all duration-300 ease-in-out ${
          color ? "bg-white" : "bg-[#ffffffbf]"
        }`}
      >
        <div className="w-full h-full flex items-center justify-between ">
          <div className="w-fit flex items-center justify-center">
            <Link to="/" className="w-[70px]">
              <img
                src={logo}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </Link>
          </div>
          <ul className="flex items-center gap-10 h-full w-fit text-lg font-semibold">
            <li className="group ">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-fcolor mb-2 w-full transition-all duration-300 ease-in-out block"
                    : "w-full transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:mb-2 block"
                }
              >
                Accueil
              </NavLink>
            </li>
            <li className="group">
              <NavLink
                to="AllProducts"
                className={({ isActive }) =>
                  isActive
                    ? "text-fcolor mb-2 w-full transition-all duration-300 ease-in-out block"
                    : "w-full transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:mb-2 block"
                }
              >
                Produits
              </NavLink>
            </li>
            <li className="group">
              <NavLink
                to="About"
                className={({ isActive }) =>
                  isActive
                    ? "text-fcolor mb-2 w-full transition-all duration-300 ease-in-out block"
                    : "w-full transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:mb-2 block"
                }
              >
                À propos de nous
              </NavLink>
            </li>
            <li className="group">
              <NavLink
                to="Countact"
                className={({ isActive }) =>
                  isActive
                    ? "text-fcolor mb-2 w-full transition-all duration-300 ease-in-out block"
                    : "w-full transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:mb-2 block"
                }
              >
                Contactez-nous
              </NavLink>
            </li>
          </ul>
          <ul className="flex items-center gap-4 h-full w-fit">
            <li className="h-full flex items-center">
              <a
                href="https://www.instagram.com/groupethika/"
                target="_blank"
                rel="noreferrer"
                className="w-[35px] h-[35px] rounded-full flex justify-center items-center group transition-all duration-300 ease-linear hover:bg-[#c13584] "
              >
                <FaInstagram className=" text-[23px] text-[#c13584] group-hover:text-white transition-all duration-300 ease-linear" />
              </a>
            </li>
            <li className="h-full flex items-center">
              <a
                rel="noreferrer"
                href="https://www.facebook.com/THIKA.GROUPE/?locale=fr_FR"
                target="_blank"
                className="w-[35px] h-[35px] rounded-full flex justify-center items-center group transition-all duration-300 ease-linear hover:bg-[#1877f2] "
              >
                <FaFacebook className=" text-[23px] text-[#1877f2] group-hover:text-white transition-all duration-300 ease-linear" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <nav
        className={`h-[70px]  px-3 md:px-9 transition-all duration-300 z-[1000] ease-in-out ${
          color ? "bg-white" : "bg-[#ffffffbf]"
        } w-full drop-shadow-lg md:hidden block fixed z-50 top-0 left-0`}
      >
        <div className="w-full h-full flex items-center justify-between">
          <div className="w-fit flex items-center justify-center">
            <Link to="/" className="w-[70px]">
              <img
                src={logo}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </Link>
          </div>
          <div className=" cursor-pointer" onClick={() => setShowMenu(true)}>
            <motion.div
              key="1"
              transition={{ duration: 0.8, ease: "easeInOut" }}
              initial={{ scale: 0, opacity: 0, rotate: "0deg" }}
              animate={{ scale: 1, opacity: 1, rotate: "360deg" }}
              exit={{ scale: 0, opacity: 0, rotate: "0deg" }}
              className="w-full"
            >
              <FaBars className="text-[25px] hover:text-fcolor transition-all duration-300 ease-in-out" />
            </motion.div>
          </div>
        </div>
        <AnimatePresence mode="hidden">
          {showMenu && (
            <motion.div
              key="3"
              transition={{ duration: 0.5, ease: "easeInOut" }}
              initial={{
                opacity: 0,
                width: "100px",
                height: "100px",
                borderBottomRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderTopRightRadius: "100%",
                zIndex: "10000",
              }}
              animate={{
                opacity: 1,
                width: "100%",
                height: "100vh",
                borderBottomRightRadius: "0",
                borderBottomLeftRadius: "0",
                borderTopRightRadius: "0",
              }}
              exit={{
                opacity: 0,
                width: "100px",
                height: "100px",
                borderBottomRightRadius: "100%",
                borderBottomLeftRadius: "100%",
                borderTopRightRadius: "100%",
              }}
              className=" absolute top-0 left-0 w-full h-full bg-tcolor flex flex-col z-[1000] pb-3 justify-between  px-3 "
            >
              <div className="flex items-center justify-between h-[70px] w-full">
                <Link
                  to="/"
                  className="w-[70px]"
                  onClick={() => setShowMenu(false)}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </Link>
                <div
                  onClick={() => setShowMenu(false)}
                  className=" cursor-pointer"
                >
                  <motion.div
                    key="2"
                    transition={{ duration: 0.8, ease: "easeInOut" }}
                    initial={{ scale: 0, opacity: 0, rotate: "0deg" }}
                    animate={{ scale: 1, opacity: 1, rotate: "360deg" }}
                    exit={{ scale: 0, opacity: 0, rotate: "0deg" }}
                  >
                    <RiCloseFill className="text-[33px] text-white hover:text-fcolor transition-all duration-300 ease-in-out" />
                  </motion.div>
                </div>
              </div>
              <ul className=" ps-2 flex flex-col items-center justify-center gap-10 h-[calc(100%-300px)] w-fit text-2xl text-white font-semibold">
                <motion.li
                  key="1"
                  transition={{ duration: 1, ease: "easeInOut" }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  className="group  w-full "
                >
                  <NavLink
                    to="/"
                    onClick={() => setShowMenu(false)}
                    className={({ isActive }) =>
                      isActive
                        ? "text-fcolor ml-3 flex items-center gap-4  w-fit transition-all duration-300 ease-in-out"
                        : "flex items-center gap-4 text-white ml-0  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                    }
                  >
                    <FcHome className="text-3xl" />
                    Accueil
                  </NavLink>
                </motion.li>
                <motion.li
                  key="2"
                  transition={{ duration: 1, ease: "easeInOut" }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  className="group w-full  "
                >
                  <NavLink
                    to="AllProducts"
                    onClick={() => setShowMenu(false)}
                    className={({ isActive }) =>
                      isActive
                        ? "text-fcolor ml-3 flex items-center gap-4  w-fit transition-all duration-300 ease-in-out"
                        : "flex items-center gap-4 text-white ml-0  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                    }
                  >
                    <FcShop className="text-3xl" />
                    Produits
                  </NavLink>
                </motion.li>
                <motion.li
                  key="3"
                  transition={{ duration: 1, ease: "easeInOut" }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  className="group w-full  "
                >
                  <NavLink
                    to="about"
                    onClick={() => setShowMenu(false)}
                    className={({ isActive }) =>
                      isActive
                        ? "text-fcolor ml-3 flex items-center gap-4  w-fit transition-all duration-300 ease-in-out"
                        : "flex items-center gap-4 text-white ml-0  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                    }
                  >
                    <FcInfo className="text-3xl" />À propos de nous
                  </NavLink>
                </motion.li>
                <motion.li
                  key="4"
                  transition={{ duration: 1, ease: "easeInOut" }}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  className="group w-full  "
                >
                  <NavLink
                    to="Countact"
                    onClick={() => setShowMenu(false)}
                    className={({ isActive }) =>
                      isActive
                        ? "text-fcolor ml-3 flex items-center gap-4  w-fit transition-all duration-300 ease-in-out"
                        : "flex items-center gap-4 text-white ml-0  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                    }
                  >
                    <FcAssistant className="text-3xl" />
                    Contactez-nous
                  </NavLink>
                </motion.li>
              </ul>
              <ul className="flex items-center justify-center gap-7 w-full h-[100px]">
                <li className="h-full flex items-center">
                  <motion.a
                    onClick={() => setShowMenu(false)}
                    key="a"
                    transition={{ duration: 1, ease: "easeInOut" }}
                    initial={{ scale: 0, opacity: 0, rotate: "360deg" }}
                    animate={{ scale: 1, opacity: 1, rotate: "0deg" }}
                    exit={{ scale: 0, opacity: 0, rotate: "360deg" }}
                    href="https://www.instagram.com/groupethika/"
                    target="_blank"
                    rel="noreferrer"
                    className="w-[50px] h-[50px] rounded-full flex justify-center items-center bg-[#c13584] "
                  >
                    <FaInstagram className=" text-[30px]  text-white" />
                  </motion.a>
                </li>
                <li className="h-full flex items-center">
                  <motion.a
                    onClick={() => setShowMenu(false)}
                    key="b"
                    transition={{ duration: 1, ease: "easeInOut" }}
                    initial={{ scale: 0, opacity: 0, rotate: "-360deg" }}
                    animate={{ scale: 1, opacity: 1, rotate: "0deg" }}
                    exit={{ scale: 0, opacity: 0, rotate: "-360deg" }}
                    rel="noreferrer"
                    href="https://www.facebook.com/THIKA.GROUPE/?locale=fr_FR"
                    target="_blank"
                    className="w-[50px] h-[50px] rounded-full flex justify-center items-center bg-[#1877f2] "
                  >
                    <FaFacebook className=" text-[30px] text-white transition-all duration-300 ease-linear" />
                  </motion.a>
                </li>
              </ul>
              <div className="h-[100px] flex justify-center items-center w-full">
                <p className="text-white text-lg">
                  © Copyright{" "}
                  <span className=" font-bold text-fcolor">
                    {new Date().getFullYear()}
                  </span>{" "}
                  All rights reserved
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </>
  );
}

export default Navebar;
// w-full h-[calc(100vh-80px)]
