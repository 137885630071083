import React, { useEffect } from "react";
import img from "../assets/bigbaner.jpg";
import { MdEmail, MdFacebook, MdPhone, MdPhoneIphone } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import Aos from "aos";
import "aos/dist/aos.css";
function Conract() {
   useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="pt-[100px] min-h-[100vh] px-3 md:px-9 relative ">
      <div className="w-full h-full bg-[#00000054] z-10 absolute top-0 left-0 "></div>
      <div className=" absolute top-0 left-0 w-full h-full">
        <img
          src={img}
          alt="logo-baner"
          className="w-full h-full  object-fill"
        />
      </div>
      <div className="w-full h-full relative z-50 flex flex-wrap gap-4 items-center justify-center">
        <div
          data-aos-duration="1000"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Service consommateur:
          </h1>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 31 97 20 25</p>
          </div>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 559 444 545</p>
          </div>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 559 444 546</p>
          </div>
        </div>
        <div
           data-aos-duration="1300"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Service commercial gros:
          </h1>

          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 782 788 882</p>
          </div>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px] ">(0) 559 357 193</p>
          </div>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px] ">(0) 557 404 651</p>
          </div>
        </div>
        <div
           data-aos-duration="1600"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Commercial Alger:
          </h1>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 560 054 952</p>
          </div>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 560 660 374</p>
          </div>
        </div>
        <div
           data-aos-duration="1900"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Service commercial détail:
          </h1>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 561 632 550</p>
          </div>
        </div>
        <div
           data-aos-duration="2100"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Service Marketing:
          </h1>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdPhoneIphone className="text-[30px] text-fcolor" />
            <p className="text-[20px]">(0) 557 371 021</p>
          </div>
        </div>
        <div
           data-aos-duration="2400"
        data-aos="zoom-in"
          className=" w-full sm:w-[280px]  md:w-[320px] h-[200px] p-2 rounded-xl bg-[#ffffffbf] hover:bg-white transition-all duration-300 ease-in-out">
          <h1 className="text-center text-2xl text-fcolor font-bold mb-3">
            Réseaux Sociaux & Email:
          </h1>
          <a
            href="https://www.facebook.com/THIKA.GROUPE/photos?locale=fr_FR"
            target="_blank"
            rel="noreferrer"
            className="flex justify-between items-center px-3 mb-3"
          >
            <MdFacebook className="text-[30px] text-fcolor" />
            <p className="text-[20px]">Groupe THIKA مجمع الثقة</p>
          </a>
          <a
            href="https://www.facebook.com/THIKA.GROUPE/photos?locale=fr_FR"
            target="_blank"
            rel="noreferrer"
            className="flex justify-between items-center px-3 mb-3"
          >
            <AiFillInstagram className="text-[30px] text-fcolor" />
            <p className="text-[20px]">Groupe THIKA مجمع الثقة</p>
          </a>
          <div className="flex justify-between items-center px-3 mb-3">
            <MdEmail className="text-[30px] text-fcolor" />
            <p className="text-[20px]">sarlthika@gmail.com</p>
          </div>
        </div>
        <p
          
        
          className="text-center text-3xl text-white mt-4 mb-4">
          Adresse : Zone d'activité Ain-Smara N° 41 Constantine Algérie,
          Constantine, Algeria, 25006
        </p>
      </div>
    </div>
  );
}

export default Conract;
