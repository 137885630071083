import { Routes, Route } from "react-router";
import { Navebar, ProductInfo, Footer } from "./components/index";
import { AllProducts, Home, AboutUs, Conract } from "./pages/index";

function App() {
  return (
    <div className=" relative ">
      <Navebar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="ProductInfo/:id" element={<ProductInfo />} />
        <Route path="AllProducts" element={<AllProducts />} />
        <Route path="About" element={<AboutUs />} />
        <Route path="Countact" element={<Conract />} />
      </Routes>
      <Footer />
    </div>
  );
}
// "homepage": "http://www.groupethika.com",

export default App;
