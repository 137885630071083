import React from "react";
import logo from "../assets/logo thika.png";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { FcAssistant, FcHome, FcInfo, FcShop } from "react-icons/fc";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import LastFooter from "./LastFooter";
function Footer() {
  return (
    <div className="w-full min-h-[55vh]  bg-tcolor pt-5">
      <div className="grid w-full min-h-[300px] grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-20 px-3 md:px-9 ">
        <div className="w-full h-full">
          <img
            src={logo}
            alt="logo"
            className="w-[100px] h-[100px] block mx-auto md:mx-0"
          />
          <p className="text-white text-lg text-center md:text-start">
            L’entreprise THIKA est une entreprise familiale qui a été fondé en
            1994, zone d'activité Ain-Smara Constantine, dont le secteur
            d’activité est la distribution des produits alimentaire de grande
            consommation.
          </p>
        </div>
        <div className="h-full">
          <h1 className="text-fcolor text-3xl text-center font-bold mb-7">
            Liens Rapides
          </h1>
          <ul className=" ps-10 flex flex-col items-center  gap-5 h-full w-fit mx-auto md:mx-0 md:w-full text-2xl text-white font-semibold">
            <motion.li
              key="1"
              transition={{ duration: 1, ease: "easeInOut" }}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="group  w-full "
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out text-fcolor"
                    : "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                }
              >
                <FcHome className="text-3xl" />
                Accueil
              </NavLink>
            </motion.li>
            <motion.li
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key="2"
              transition={{ duration: 1, ease: "easeInOut" }}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="group w-full  "
            >
              <NavLink
                to="AllProducts"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out text-fcolor"
                    : "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                }
              >
                <FcShop className="text-3xl" />
                Produits
              </NavLink>
            </motion.li>
            <motion.li
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key="3"
              transition={{ duration: 1, ease: "easeInOut" }}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="group w-full "
            >
              <NavLink
                to="About"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out text-fcolor"
                    : "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                }
              >
                <FcInfo className="text-3xl" />À propos de nous
              </NavLink>
            </motion.li>
            <motion.li
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key="4"
              transition={{ duration: 1, ease: "easeInOut" }}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              className="group w-full  "
            >
              <NavLink
                to="Countact"
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out text-fcolor"
                    : "flex items-center gap-4  w-fit transition-all duration-300 ease-in-out group-hover:text-fcolor group-hover:ml-3"
                }
              >
                <FcAssistant className="text-3xl" />
                Contactez-nous
              </NavLink>
            </motion.li>
          </ul>
        </div>
        <div className="w-full h-full">
          <h1 className="text-fcolor text-3xl text-center font-bold mb-7">
            Réseaux Sociaux
          </h1>
          <p className="text-white text-xl mb-10 text-center">
            suivez-nous sur les Réseaux Sociaux:
          </p>
          <ul className="flex items-center justify-center gap-7 w-full h-[100px]">
            <li className="h-full flex items-center">
              <motion.a
                key="a"
                transition={{ duration: 1, ease: "easeInOut" }}
                initial={{ scale: 0, opacity: 0, rotate: "360deg" }}
                animate={{ scale: 1, opacity: 1, rotate: "0deg" }}
                exit={{ scale: 0, opacity: 0, rotate: "360deg" }}
                href="https://www.instagram.com/groupethika/"
                target="_blank"
                rel="noreferrer"
                className="w-[50px] h-[50px] rounded-full flex justify-center items-center group transition-all duration-300 ease-linear bg-[#c13584] md:bg-transparent md:hover:bg-[#c13584] "
              >
                <FaInstagram className=" text-[30px] text-white md:text-[#c13584] md:group-hover:text-white transition-all duration-300 ease-linear" />
              </motion.a>
            </li>
            <li className="h-full flex items-center">
              <motion.a
                key="b"
                transition={{ duration: 1, ease: "easeInOut" }}
                initial={{ scale: 0, opacity: 0, rotate: "-360deg" }}
                animate={{ scale: 1, opacity: 1, rotate: "0deg" }}
                exit={{ scale: 0, opacity: 0, rotate: "-360deg" }}
                rel="noreferrer"
                href="https://www.facebook.com/THIKA.GROUPE/?locale=fr_FR"
                target="_blank"
                className="w-[50px] h-[50px] rounded-full flex justify-center items-center group transition-all duration-300 ease-linear bg-[#1877f2] md:bg-transparent md:hover:bg-[#1877f2] "
              >
                <FaFacebook className=" text-[30px] text-white md:text-[#1877f2] md:group-hover:text-white transition-all duration-300 ease-linear" />
              </motion.a>
            </li>
          </ul>
        </div>
      </div>
      <LastFooter />
    </div>
  );
}

export default Footer;
